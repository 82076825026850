import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import CustomModal from "../Modal/CustomModal"; // Ensure you import your modal correctly
import article from "../../assets/icons/Article.svg";
import backgroundCircle from "../../assets/icons/Background circle.svg";
import CSoil from "../../assets/images/1A_S2_T.jpg";
import IncorrectSoil from "../../assets/images/Incorrect_Soil_Image.png";
import LeafSoil from "../../assets/images/Image_With_Leaf.png";
import GrassSoil from "../../assets/images/Image_With_Grasses.png";
import SoilVideo from "../../assets/images/SoilInstructionsVideo.mp4";
import RightCircle from "../../assets/icons/CheckCircle.svg";
import WrongCircle from "../../assets/icons/XCircle.svg";
import circle from "../../assets/icons/Circle.svg";
import ProgressBar from "./progressbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import plusSign from "../../assets/image/plus_image.png";
import StateandDistrict from "../../assets/states-and-districts.json";
import { useNavigate } from "react-router-dom";

import "./SoilTest.css";

const Soiltest = () => {
  const [soilParams, setSoilParams] = useState({});
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = localStorage.getItem("currentAdress");
  const Humidity = localStorage.getItem("Humidity");
  const SoilTemprature = localStorage.getItem("soilTemperature");
  const SoilMoisture = localStorage.getItem("soilMoisture");
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const farmId = localStorage.getItem("FarmId");
  const [currentId, setCurrentId] = useState(farmId);

  useEffect(() => {
    const Updateid = async () => {
      const farmfetchid = localStorage.getItem("FarmId");
      setCurrentId(farmfetchid);
    };

    window.addEventListener("storageChange", Updateid);

    return () => {
      window.removeEventListener("storageChange", Updateid);
    };
  }, []);

  const [rgbValues, setRgbValues] = useState({
    mean: null,
    median: null,
    meanHsv: null,
    medianHsv: null,
  });
  const [imageFileName, setImageFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const fileInputRef = useRef(null);
  // Function to toggle modal
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const id = localStorage.getItem("GeneratedKey");
  const [districts, setDistricts] = useState([]);
  const [formData, setFormData] = useState({
    filename: "",
    kindOfFarming: "",
    villageCity: "",
    previousCrop: "",
    sourceOfWater: "",
    seedingDate: "",
    testDate: "",
    state: "",
    currentCrop: "",
    season: "",
    MeanRed: "",
    MeanGreen: "",
    MeanBlue: "",
    MeanHue: "",
    MeanSaturation: "",
    MeanValue: "",
    MedianRed: "",
    MedianGreen: "",
    MedianBlue: "",
    MedianHue: "",
    MedianSaturation: "",
    MedianValue: "",
    RednessIndex: "",
    ColourationIndex: "",
    HueIndex: "",
    SaturationIndex: "",
    location: "",
    Humidity: "",
    SoilTemperature: "", // Corrected spelling from 'Temprature' to 'Temperature'
    SoilMoisture: "",
    predictions: {
      Boron: "",
      Cu: "",
      EC: "",
      Fe: "",
      K_Potassium: "",
      Mn: "",
      Nitrogen: "",
      OrganicCarbon: "",
      OrganicMatter: "",
      Phosphorus: "",
      Sulphur: "",
      Zn: "",
      pH: "",
    },
    bulkdensity: "",
    SHI: "",
    Insights: "",
    cropVegRecommendation: "",
  });

  formData.location = location || formData.state;

  // Define default average values
  const averageHumidity = 50; // Example average value for Humidity
  const averageSoilTemperature = 25; // Example average value for Soil Temperature (in °C)
  const averageSoilMoisture = 0.3; // Example average value for Soil Moisture (%)

  // Assign values, using the average if null or undefined
  formData.Humidity = Humidity != 'null' ? Humidity : averageHumidity;
  formData.SoilTemperature = SoilTemprature != 'null' ? SoilTemprature : averageSoilTemperature;
  formData.SoilMoisture = SoilMoisture != 'null' ? SoilMoisture : averageSoilMoisture;


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  //state and district handle change
  const handleChangeState = (e) => {
    const selectedState = e.target.value;
    setFormData((prev) => ({ ...prev, state: selectedState, villageCity: "" }));

    const stateObj = StateandDistrict.find(
      (obj) => obj.state === selectedState
    );

    setDistricts(stateObj ? stateObj.districts : []);
    // Reset district when state changes
  };

  const handleChangeDistrict = (e) => {
    const selectedDistrict = e.target.value;

    // Update the selected district in formData
    setFormData((prev) => ({ ...prev, villageCity: selectedDistrict }));
  };

  //upload image
  const handleClick = () => {
    fileInputRef.current.click();
  };

  //handle image change
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can handle the uploaded file here (e.g., display it or upload it to a server)
      const imageUrl = URL.createObjectURL(file); // Create a URL for the uploaded image
      setSelectedImage(imageUrl);
      const formData = new FormData();
      formData.append("image", file);

      try {
        // Upload image to the server
        const ImageResponse = await axios.post(
          "https://soiltech.ai/fastapi/classifyImage",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" }, // You can omit this; axios will set it
          }
        );
        console.log("The response after upload is:", ImageResponse.data);

        if (ImageResponse.data.success) {
          const response = await axios.post(
            "https://soiltech.ai/api/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const extractRgbValues = (file) => {
            setIsProcessing(true); // Start processing
            setProgress(0);
            const img = new Image();
            const reader = new FileReader();

            reader.onload = function (event) {
              img.src = event.target.result;

              img.onload = async () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                // Get the image data
                const imageData = ctx.getImageData(0, 0, img.width, img.height);
                const { data } = imageData;

                // Arrays to store RGB values for median calculation
                const rArray = [],
                  gArray = [],
                  bArray = [];
                let r = 0,
                  g = 0,
                  b = 0,
                  totalPixels = data.length / 4;

                for (let i = 0; i < data.length; i += 4) {
                  const red = data[i];
                  const green = data[i + 1];
                  const blue = data[i + 2];

                  rArray.push(red);
                  gArray.push(green);
                  bArray.push(blue);

                  r += red;
                  g += green;
                  b += blue;
                  if (i % 10000 === 0) {
                    setProgress(Math.floor((i / data.length) * 100));

                    // Give some time to render the UI update
                    await new Promise((resolve) => setTimeout(resolve, 0));
                  }
                }

                // Calculate Mean RGB
                const meanRgb = {
                  r: Math.floor(r / totalPixels),
                  g: Math.floor(g / totalPixels),
                  b: Math.floor(b / totalPixels),
                };

                // Calculate Median RGB
                const median = (arr) => {
                  arr.sort((a, b) => a - b);
                  const middle = Math.floor(arr.length / 2);
                  return arr.length % 2 === 0
                    ? (arr[middle - 1] + arr[middle]) / 2
                    : arr[middle];
                };

                const medianRgb = {
                  r: median(rArray),
                  g: median(gArray),
                  b: median(bArray),
                };

                // Convert RGB to HSV
                const rgbToHsv = (r, g, b) => {
                  r /= 255;
                  g /= 255;
                  b /= 255;
                  const max = Math.max(r, g, b),
                    min = Math.min(r, g, b);
                  const delta = max - min;
                  let h = 0,
                    s = max === 0 ? 0 : delta / max,
                    v = max;

                  if (delta !== 0) {
                    if (max === r) {
                      h = (g - b) / delta;
                    } else if (max === g) {
                      h = (b - r) / delta + 2;
                    } else {
                      h = (r - g) / delta + 4;
                    }
                    h *= 60;
                    if (h < 0) h += 360;
                  }

                  return { h, s, v };
                };

                const hsvArray = [];
                for (let i = 0; i < data.length; i += 4) {
                  const red = data[i];
                  const green = data[i + 1];
                  const blue = data[i + 2];
                  hsvArray.push(rgbToHsv(red, green, blue));
                }

                const meanHsv = hsvArray.reduce(
                  (acc, hsv) => {
                    acc.h += hsv.h;
                    acc.s += hsv.s;
                    acc.v += hsv.v;
                    return acc;
                  },
                  { h: 0, s: 0, v: 0 }
                );

                meanHsv.h /= totalPixels;
                meanHsv.s /= totalPixels;
                meanHsv.v /= totalPixels;

                // Calculate Median HSV
                const medianHsv = (arr, key) => {
                  arr.sort((a, b) => a[key] - b[key]);
                  const middle = Math.floor(arr.length / 2);
                  return arr.length % 2 === 0
                    ? (arr[middle - 1][key] + arr[middle][key]) / 2
                    : arr[middle][key];
                };

                const medianHsvValues = {
                  h: medianHsv(hsvArray, "h"),
                  s: medianHsv(hsvArray, "s"),
                  v: medianHsv(hsvArray, "v"),
                };
                const RednessIndex =
                  medianRgb.r ** 2 / (medianRgb.b * medianRgb.g ** 3);
                const ColourationIndex =
                  (medianRgb.r - medianRgb.g) / (medianRgb.r + medianRgb.g);
                const HueIndex =
                  (2 * medianRgb.r - medianRgb.g - medianRgb.b) /
                  (medianRgb.g - medianRgb.b);
                const SaturationIndex =
                  (medianRgb.r - medianRgb.b) / (medianRgb.r + medianRgb.b);
                const updatedFormData = {
                  MeanRed: meanRgb.r,
                  MeanGreen: meanRgb.g,
                  MeanBlue: meanRgb.b,
                  MeanHue: meanHsv.h,
                  MeanSaturation: meanHsv.s,
                  MeanValue: meanHsv.v,
                  MedianRed: medianRgb.r,
                  MedianGreen: medianRgb.g,
                  MedianBlue: medianRgb.b,
                  MedianHue: medianHsvValues.h,
                  MedianSaturation: medianHsvValues.s,
                  MedianValue: medianHsvValues.v,
                  RednessIndex: RednessIndex,
                  ColourationIndex: ColourationIndex,
                  HueIndex: HueIndex,
                  SaturationIndex: SaturationIndex,
                };

                setFormData((prev)=>({...prev, ...updatedFormData}));
                setIsProcessing(false); // Stop processing
                setProgress(100); // Set progress to 100% after completion
              };
            };
            reader.readAsDataURL(file);
          };
          if (response.data.filename) {
            // Save the filename returned from the server
            setImageFileName(response.data.filename);
            setFormData((prev)=> ( {...prev, filename: response.data.filename}))
            setFilePath(response.data.filePath);
            // Extract and pass RGB values to the parent component
            extractRgbValues(file);
          }

          // extract rgb values
        } else {
          toast.error("Please Upload A Soil image", {
            position: "top-center",
            autoClose: 1000,
          });
          setSelectedImage(null);
          setImageFileName("");
          setFilePath("");
        }
      } catch (error) {
        console.error("Error uploading the image", error);
      }
    }
  };
  //edit or remove image
  const handleRemoveImage = async () => {
    try {
      // Remove the image from the server and database
      await axios.delete(`https://soiltech.ai/api/image/${imageFileName}`);

      // Clear the preview and filename
      setSelectedImage(null);
      setImageFileName("");
      setFilePath("");
      setProgress(0);

      // Inform parent that image is removed (optional, could pass null to callback)
      setRgbValues(null);
    } catch (error) {
      console.error("Error deleting the image", error);
    }
  };

  //submitting the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Check if all required fields are filled
    // console.log(formData.kindOfFarming, formData.location, formData.date, imageFileName);

    if (
      !formData.kindOfFarming ||
      !formData.filename ||
      !formData.sourceOfWater ||
      !formData.currentCrop ||
      !formData.villageCity ||
      !formData.previousCrop ||
      !formData.seedingDate ||
      !formData.state ||
      !formData.season ||
      !formData.testDate
    ) {
      alert("Please fill all fields and upload an image.");
      setLoading(false);
      return;
    }

    try {
      const updatedFormData1 = {
        ...formData,
        filename: filePath,
      };

      setFormData(updatedFormData1);
      // Step 1: Get predictions from the prediction API
      const predictionResponse = await axios.post(
        "https://soiltech.ai/fastapi/predictForImage",
        formData,
        { headers: { "Content-Type": "application/json" } }
      );

      const predictions = predictionResponse.data?.predictions;
      console.log("the prediction is", predictions);

      // Use local variables to store the updated formData
      const reportdata = {
        ...predictions,
        SHI: predictionResponse.data?.soil_health_index,
        bulkdensity: predictionResponse.data?.bulk_density,
      };
      const updatedFormData = {
        ...formData,
        predictions: reportdata,
        SHI: predictionResponse.data?.soil_health_index,
        bulkdensity: predictionResponse.data?.bulk_density,
      };

      // Prepare soilParams for crop recommendations
      const updatedSoilParams = {
        pH: predictions.pH,
        EC: predictions.EC,
        OC: predictions.OrganicCarbon, // Assuming OrganicCarbon as OC
        N: predictions.Nitrogen, // Nitrogen
        P: predictions.Phosphorus, // Phosphorus
        K: predictions.K_Potassium, // Potassium
      };

      // Step 2: Get crop recommendations based on updated soil parameters
      const recommendationResponse = await axios.post(
        "https://soiltech.ai/fastapi/recommend",
        {
          soil_params: updatedSoilParams,
          location: formData.location,
          moisture: parseFloat(formData.SoilMoisture),
          temperature: parseFloat(formData.SoilTemperature), // Placeholder value, adjust as needed
          humidity: parseFloat(formData.Humidity), // Placeholder value, adjust as needed
        }
      );

      const { recommendation } = recommendationResponse.data;

      const cropRecommendationResponse = await axios.post(
        "https://soiltech.ai/fastapi/recommend/cops-veg-fruit",
        {
          soil_params: updatedSoilParams,
          location: formData.location,
          moisture: parseFloat(formData.SoilMoisture),
          temperature: parseFloat(formData.SoilTemperature), // Placeholder value, adjust as needed
          humidity: parseFloat(formData.Humidity), // Placeholder value, adjust as needed
        }
      );
      const rawRecommendationData =
        cropRecommendationResponse.data.recommendation;

      // Clean up the string
      const cleanedData = rawRecommendationData
        .replace(/```JSON/g, "")
        .replace(/```Json/g, "")
        .replace(/```json/g, "") // Remove opening code block
        .replace(/```/g, "") // Remove closing code block
        .replace(/'/g, '"') // Replace single quotes with double quotes
        .trim(); // Remove leading and trailing whitespace
        console.log('the clean data',cleanedData)
      
      const jsonData = JSON.parse(cleanedData.slice(0, cleanedData.lastIndexOf('}]}') + 3));
      console.log('the clean data',jsonData)
      const resultArray = jsonData["Crops and Vegetables"].map((crop) => [
        crop.name,
        crop.fertilizer.urea,
        crop.fertilizer.potash,
        crop.fertilizer.DAP,
      ]);

      // Update formData with crop recommendations (Insights)
      const finalFormData = {
        ...updatedFormData,
        Insights: recommendation,
        cropVegRecommendation: JSON.stringify(resultArray),
      };

      // Step 3: Submit the final form data to save the test results
      const finalResponse = await axios.post(
        "https://soiltech.ai/api/selftest/updateSoilTest",
        // Assuming `id` is provided elsewhere
        {
          userId: id,
          farmId: currentId,
          formData: finalFormData, // Pass the updated formData
        }
      );

      // Handle success/failure response
      if (
        finalResponse.data.message ===
          "New soil report test added suceessfully" ||
        finalResponse.data.message ===
          "new farm created and soil test added!" ||
        finalResponse.data.message ===
          "New user created, farm added, and soil test saved!"
      ) {
        toast.success("Soil test submitted successfully!", {
          position: "top-center",
          autoClose: 5000,
        });
        navigate("/soilreportpdf", { state: { formData: finalFormData } });
      } else {
        toast.error("Failed to submit soil test.", {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error processing data:", error);
      toast.error("Please Upload Another image", {
        position: "top-center",
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  // for mobile responsivenesss only
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Mobile if screen width <= 768px
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);

  // console.log(formData);
  return (
    <div className="Soiltest-container">
      <div className="Soiltest-content-1">
        <Sidebar />
      </div>
      <div className="Soiltest-content-2">
        <DHeader isMobile={isMobile} />
        <div className="Soiltest-top">
          <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>
            Farm Information & details
          </div>
          <div
            style={{
              color: "var(--Neutral-600, #4B4B4B)",
              textAlign: "justify",
            }}
          >
            Please provide the necessary details about your farm and crops to
            help us assess the soil quality accurately.
          </div>
        </div>
        <div className="Soiltest-information">
          <div className="details-form">
            <div className="details-form-1">
              <label className="details1-label">Kind of Farming</label>
              <select
                type="text"
                placeholder="i"
                name="kindOfFarming"
                className="details2-input"
                value={formData.kindOfFarming}
                onChange={handleChange}
              >
                <option value="" hidden>
                  {" "}
                  Kind of Farming
                </option>
                <option value="Subsistence Farming">
                  {" "}
                  Subsistence Farming
                </option>
                <option value="Intensive and Extensive Farming">
                  Intensive and Extensive Farming
                </option>
                <option value="Commercial Farming">Commercial Farming</option>
                <option value="Plantation Farming">Plantation Farming</option>
                <option value="Dryland Farming">Dryland Farming</option>
                <option value="Wetland Farming">Wetland Farming</option>
                <option value="Mixed Farming">Mixed Farming</option>
                <option value="Organic Farming">Organic Farming</option>
                <option value="Co-operative Farming">
                  Co-operative Farming
                </option>
                <option value="Terrace Agriculture ">
                  Terrace Agriculture{" "}
                </option>
                <option value="Crop Rotation">Crop Rotation</option>
                <option value="Dairy Farming">Dairy Farming</option>
                <option value="Ley Farming">Ley Farming</option>
              </select>

              <label className="details1-label">Current Crop</label>
              <input
                type="text"
                placeholder="corn"
                className="details1-input"
                name="currentCrop"
                value={formData.currentCrop}
                onChange={handleChange}
              />

              <label className="details1-label">Previous Crop</label>
              <input
                type="text"
                name="previousCrop"
                placeholder="corn"
                className="details1-input"
                value={formData.previousCrop}
                onChange={handleChange}
              />

              <label className="details1-label">Source of Water</label>
              <select
                type="text"
                name="sourceOfWater"
                placeholder="i"
                className="details2-input"
                value={formData.sourceOfWater}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Source of Water{" "}
                </option>
                <option value="Rain"> Rain</option>
                <option value="GroundWater">GroundWater</option>
                <option value="River and Lakes">River and Lakes</option>
                <option value="Streams">Streams</option>
                <option value="Reservoirs">Reservoirs</option>
                <option value="Springs">Springs</option>
              </select>
              <label className="details1-label">Seeding Date</label>
              <input
                type="date"
                name="seedingDate"
                className="details1-input"
                value={formData.seedingDate}
                onChange={handleChange}
              />
            </div>
            <div className="details-form-2">
              <label className="details1-label">Select State</label>
              <select
                className="details2-input"
                name="state"
                value={formData.state}
                onChange={handleChangeState}
              >
                <option value="" hidden>
                  Select State
                </option>
                {StateandDistrict.map((stateObj) => (
                  <option key={stateObj.state} value={stateObj.state}>
                    {stateObj.state}
                  </option>
                ))}
              </select>
              <label className="details1-label">Select City</label>
              <select
                className="details2-input"
                name="villageCity"
                value={formData.villageCity}
                onChange={handleChangeDistrict}
                disabled={!formData.state} // Disable if no state is selected
              >
                <option value="" hidden>
                  Select District
                </option>
                {districts.map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </select>
              <label className="details1-label">Select Season</label>
              <select
                className="details2-input"
                id="State"
                name="season"
                value={formData.season}
                onChange={handleChange}
              >
                <option value="" hidden>
                  {" "}
                  Select Season
                </option>
                <option value="Kharif">Kharif</option>
                <option value="Zaid">Zaid</option>
                <option value="Rabi">Rabi</option>
              </select>
              <label className="details1-label">Test Date</label>
              <input
                type="date"
                className="details1-input"
                name="testDate"
                value={formData.testDate}
                onChange={handleChange}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="Soiltest-upload">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Uploaded"
                  className="uploaded-img"
                />
              ) : (
                <div className="Soiltest-upload-image">
                  <div className="upload-1">
                    <p>
                      Before clicking an image or making a video, please read
                      these:
                    </p>
                    <img
                      src={article}
                      alt="article"
                      className="article"
                      onClick={handleModalToggle} // Trigger modal on click
                    />
                    <p>INSTRUCTIONS</p>
                  </div>

                  <div className="upload-2">
                    <div className="upload-circle">
                      <img
                        src={backgroundCircle} // Replace with your image URL
                        alt="backgroundCircle"
                        className="backgroundCircle"
                      />
                      <div>
                        <div className="plus-sign">
                          <img
                            src={plusSign}
                            className="plus-sign-image"
                            alt="Plus Sign"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="upload-cards">
                      <div className="green-circle"></div>
                      <div className="green-lines">
                        <div
                          className="green-line"
                          style={{ width: "60%" }}
                        ></div>
                        <div
                          className="green-line"
                          style={{ width: "90%" }}
                        ></div>
                      </div>
                    </div>
                    <div class="upload-cards">
                      <div className="green-circle"></div>
                      <div className="green-lines">
                        <div
                          className="green-line"
                          style={{ width: "60%" }}
                        ></div>
                        <div
                          className="green-line"
                          style={{ width: "90%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <p>Drag and drop or click here</p>
                  <p>to upload your image (Max 2 MB)</p>
                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <button
                      type="input"
                      className="upload-capture"
                      onClick={handleClick}
                    >
                      Upload Picture
                    </button>
                  </div>
                </div>
              )}
              {isProcessing && <ProgressBar progress={progress} />}
              {!isProcessing && formData.filename && (
                <p>Image processing complete.</p>
              )}
              {selectedImage && progress === 100 && (
                <button className="upload-edit" onClick={handleRemoveImage}>
                  <b>Edit</b>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="form-submit-div">
          <button
            type="submit"
            className="form-submit-btn"
            onClick={handleSubmit}
            disabled={progress !== 100}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>

      <CustomModal
        show={showModal}
        handleClose={handleModalToggle}
        message={
          <div className="Modal-Container">
            <h2 className="ModalHeader">INSTRUCTIONS</h2>
            <div className="ModalContent">
              <div className="ModalWindowLeft">
                <ol>
                  <li className="ModalText">
                    Please take the pictures/videos only under sunlight, not in
                    ambient indoor lighting, or under shade, or any dark place
                  </li>

                  <li className="ModalText">
                    Make sure the image/video uploaded/captured should only
                    cover soil, not any surrounding background.
                  </li>
                  <div className="SoilImage1Container">
                    <div className="SoilImageWrapper">
                      <img src={CSoil} alt="CorrectSoil" className="Csoil" />
                      <img
                        src={RightCircle}
                        alt="RightCircle"
                        className="RightCircle"
                      />
                    </div>
                    <div className="SoilImageWrapper">
                      <img
                        src={IncorrectSoil}
                        alt="IncorrectSoil"
                        className="IncorrectSoil"
                      />
                      <img
                        src={WrongCircle}
                        alt="WrongCircle"
                        className="WrongCircle"
                      />
                    </div>
                  </div>

                  <li className="ModalText">
                    Before clicking the picture/video, make sure to make the
                    soil surface as even as possible, and to make it devoid of
                    any disturbances such as small leaves, roots, pebbles, or
                    insects.
                  </li>
                  <div className="SoilImage2Container">
                    <div className="SoilImageWrapper">
                      <img src={LeafSoil} alt="LeafSoil" className="LeafSoil" />
                      <img
                        src={WrongCircle}
                        alt="WrongCircle"
                        className="WrongCircle"
                      />
                    </div>
                    <div className="SoilImageWrapper">
                      <img
                        src={GrassSoil}
                        alt="GrassSoil"
                        className="GrassSoil"
                      />
                      <img
                        src={WrongCircle}
                        alt="WrongCircle"
                        className="WrongCircle"
                      />
                    </div>
                  </div>
                </ol>
              </div>
              <div className="ModalWindowRight">
                <video className="SoilVideo" controls autoPlay loop muted>
                  <source src={SoilVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default Soiltest;
